import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import {colors, fonts, fontWeights} from '../../styles/theme';


/*
 * Private Elements
 */
const StyledRadioInput = styled.div`
    display: inline-block;

    input {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 0;
        width: 0;
        border: 0;
        overflow: hidden;
    }

    label {
        display: inline-block;
        background-color: ${colors.darkPurple};
        color: ${colors.white};
        font-family: ${fonts.sansSerif};
        font-weight: ${fontWeights.semiBold};
        font-size: 14px;
        text-align: center;
        padding: 15px 24px;
        margin: 0;
        transition: all 0.1s ease-in-out;
        border-radius: 30px;
        box-sizing: border-box;
    }

    label:hover {
        cursor: pointer;
        background-color: ${colors.purple};
    }

    input:checked + label {
        background-color: ${colors.black};
    }
`;


const StyledRadioToggleSwitch = styled.fieldset`
    display: inline-block;
    background-color: ${colors.darkPurple};
    border: none;
    border-radius: 30px;
    padding: 5px;
    margin: 10px 0;
    overflow: hidden;

    div:not(:last-of-type) {
        margin-right: 5px;
    }
`;


/*
 * Public Elements
 */
const RadioInput = ({group, label, value, selectedValue, onClick}) => {
    // take value (should be unique) and turn into valid id
    const id = value.replace(/\W/g, '_');

    return (
        <StyledRadioInput>
            <input
                type="radio"
                id={id}
                name={group}
                value={value}
                checked={selectedValue === value}
                onChange={e => onClick(e.target.value)}
            />
            <label htmlFor={id}>{label}</label>
        </StyledRadioInput>
    );
};

RadioInput.propTypes = {
    group: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    selectedValue: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const RadioToggleSwitch = ({children}) => (
    <StyledRadioToggleSwitch>
        {children}
    </StyledRadioToggleSwitch>
);

RadioToggleSwitch.propTypes = {
    children: PropTypes.node.isRequired,
};


/*
 * Exports
 */
export {
    RadioToggleSwitch,
    RadioInput,
};

/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';

import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Slider from 'react-slick';

import DragCursor from '../../../images/about/company/drag-cursor.no-inline.svg';


/*
 * Private Elements
 */
const sliderCss = css`
    .slick-list {
        position: relative;
        z-index: 10;
        cursor: url(${DragCursor}), auto;
    }

    .slick-slide div  {
        outline: none !important;
    }

    .slick-slide {
        opacity: 50%;
        transition: opacity 800ms;
    }

    .slick-center {
        opacity: 100%;
    }

    .slick-arrow {
        display: none !important;
    }
`;

/* eslint-disable i18next/no-literal-string */
const StyledSlide = styled.div`
    margin-top: ${props => (props.odd ? '60px' : 0)};
    padding: 0 5px;
  
    @media (min-width: 600px) {
        padding: 0 10px;
    }

    @media (min-width: 1000px) {
        padding: 0 20px;
    }
`;
/* eslint-enable i18next/no-literal-string */


/*
 * Public Elements
 */
const CompanyPhotosCarousel = ({images}) => {
    const sliderSettings = {
        infinite: true,
        centerMode: true,
        speed: 600,
        swipeToSlide: true,
        focusOnSelect: true,
        slidesToShow: 1,
        centerPadding: '15%',
    };

    return (
        <Slider {...sliderSettings} css={sliderCss}>
            {images.map((image, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <StyledSlide key={i} odd={i % 2 === 0}>
                    <GatsbyImage image={getImage(image.localFile)} alt={image.image_alt} />
                </StyledSlide>
            ))}
        </Slider>
    );
};

CompanyPhotosCarousel.propTypes = {
    images: PropTypes.array.isRequired,
};



/*
 * Exports
 */

export default CompanyPhotosCarousel;
